import React, { Component } from "react";
import ProductList from "./ProductList";
import Carousel from "../pages/Carousel";
import { Text } from '../languages/Language';
import { BANNER as SHOWBANNER } from '../constants';

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {

      token: false
    }
  }

  onAdd = (item) => {

    this.props.onAdd(item)
  }

  onRemove = (item) => {

    this.props.onRemove(item)
  }

  render() {
    return (
      <div
        className={`${this.props.isCategoryOpen ? "w-full text-inherit h-full hidden md:flex":
        (this.props.isCartOpen ? "w-full text-inherit h-full hidden md:flex" : "flex w-full text-inherit h-full ") }`}

      >
        {/* Mittelbereich */}
        <div
          className="flex-auto w-full h-full "
        >
          {
            this.props.productWebCategory
            && this.props.productWebCategory === "Home"
            && SHOWBANNER
            ?
            (
              this.props.isLoggedIn
                ? ""
                :
                <>
                  <h2
                    className="text-lg font-medium text-company-primary-color mb-2"
                  >
                    &nbsp;
                  </h2>
                  <Carousel />
                </>
            )
            :null
          }
          <h2
            className="text-md font-medium text-company-primary-color my-2 w-full sticky bg-white top-0"
          >
            <Text tid={`${this.props.productWebCategory
              }`
            } />

          </h2>
          <div
            className={`${this.props.productWebCategory !== "Home"
              ? "h-[calc(100%-2.5rem)]"
              : !SHOWBANNER
                ? "h-[calc(100%-2.5rem)]"
                : "h-[calc(100%-20.75rem)]"
              }
          flex border-t-4 border-b-4 border-r-2 w-full py-2 overflow-y-auto border-company-primary-color-100 `}
          >
            <ProductList
              items={this.props.items}
              allFavorites={this.props.allFavorites}
              allCampaigns={this.props.allCampaigns}
              allNewItems={this.props.allNewItems}
              allSold={this.props.allSold}
              itemSubCategoryName={this.props.itemSubCategoryName}
              notSold={this.props.notSold}
              productWebCategory={this.props.productWebCategory}
              itemCategoryName={this.props.itemCategoryName}
              productWebSubCategory={this.props.productWebSubCategory}
              onAdd={this.onAdd}
              onRemove={this.onRemove}
              allCategories={this.props.allCategories}
              onAddAllFavorites={this.props.onAddAllFavorites}
              onRemoveAllFavorites={this.props.onRemoveAllFavorites}
              handleChangeCount={this.props.handleChangeCount}
              value={this.props.value}
              filteredData={this.props.filteredData}
            />
          </div>
        </div>
        <div
          className="flex-none lg:flex flex-col sm:hidden"
        >
        </div>

      </div>

    );
  }
}

export default Content;
