import React from 'react'
import { ReactComponent as PDFFileIcon } from '../assets/pic/PDFFileIcon.svg';
import FetchPDFFile from '../fetch/useFetchPDFFile'

export default function BillToPDF(props) {

    const makePDF = () => {
        FetchPDFFile(props.invoiceNr) //service for categories
        .then(json => {
            console.log(props.invoiceNr)
          const link = json && json.dataSet[0].pdfUrl;
          console.log(json)
          // const link = 'http://akpa2.cobim.de:6803/aysales/api/pdf?dn=RB22-082200&fn=018&ar=241&tr=3&token=8A9E87BAF53F95DE1D925965B32F88DE80B2BD671E46866EAB165D57A2F29C25B77EB7D24DB852681FBA183750505E9B'
           window.open(link, '_blank', 'noreferrer');
        })

    }

    return (
        <div>
            <button onClick={() => makePDF()} className="flex items-center justify-center hover:opacity-75">
                <PDFFileIcon className='w-8 h-8 fill-company-primary-color' /></button>
        </div>
    )
}
