import React, { useState } from "react";
import { ReactComponent as FontAwesomeIcon } from '../assets/pic/bars-solid.svg';
import logo from '../assets/pic/logo.png';
import { ReactComponent as SearchIcon } from '../assets/pic/search.svg';
import LanguageSelector from '../languages/LanguageSelector';
import "../style/styles.css";
import Order from "../shop/Order";
import Bill from "../shop/Bill";
import { Text } from '../languages/Language';
import UserProfile from "./UserProfile";
import LoginModal from "../shop/LoginModal";
import RegisterModal from "../shop/RegisterModal";
import Impressum from "../pages/Impressum";
import Datenschutz from "../pages/Datenschutz";
import AGB from "../pages/AGB";
import Contact from "../pages/Contact";
import AboutUs from "../pages/AboutUs";

export default function Menu2(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [term, setTerm] = useState('');
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  const handleShowRegisterModal = (value) => {
    setShowRegisterModal(value);
  };


  const handleInputChange = (event) => {
    setTerm(event.target.value);
    props.search(event.target.value);
  };

  const clickHandler = () => {
    setMenuOpen(!menuOpen);
  };

  const parentHandleToken = (isLogged) => {
    props.setAppToken(isLogged);
  };

  const handleOpen = () => {
    props.WebCategory("Home", null);
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="text-white bg-menu-secondary-color w-full pt-2">
      <div className="container mx-auto flex justify-between items-center">
      <div className="grid flex-none items-center content-center w-24 sm:w-32 bg-white p-2 h-5/6 ">
            <img className="flex items-center justify-center" src={logo} alt="Logo" />
          </div>
        <div className="hidden md:flex space-x-4">
          <LoginModal
                              setShowRegisterModal={handleShowRegisterModal}

          parentHandleToken={parentHandleToken}
          onGetItems={() => props.onGetItems()}
          onGetCategories={() => props.onGetCategories()}/>
          <RegisterModal showModal={showRegisterModal} setShowModal={handleShowRegisterModal}></RegisterModal>
          <LanguageSelector></LanguageSelector>
        </div>
        <div className="md:hidden mr-2">
          <button onClick={toggleMenu} className="text-white">
            ☰
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden bg-menu-secondary-color text-white p-4 w-full">
          <div ></div>
          <div className="text-start space-y-4">
            <LanguageSelector></LanguageSelector>
          <LoginModal
          setShowRegisterModal={handleShowRegisterModal}
          parentHandleToken={parentHandleToken}
          onGetItems={() => props.onGetItems()}
          onGetCategories={() => props.onGetCategories()}/>

          <RegisterModal showModal={showRegisterModal} setShowModal={handleShowRegisterModal}/>
          <hr></hr>
          <Impressum ShowImpressum={(value) => props.ShowImpressum(value)}/>
          <Datenschutz ShowDatenschutz={(value) => props.ShowDatenschutz(value)}/>
          <AGB ShowAGB={(value) => props.ShowAGB(value)}/>
          <Contact ShowContact={(value) => props.ShowContact(value)}/>
          <AboutUs ShowAboutUs={(value) => props.ShowAboutUs(value)}/>

        </div>
        </div>
      )}
    </nav>
  );
}
