import React, { useState } from 'react';
import { Text } from '../languages/Language';
import { URL as NEWURL } from '../constants';
import { VENDORID as NEWVENDORID } from '../constants';
import Loading from '../components/Loading';

export default function OrderItems(props) {
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');


    const sendOrder = () => {
        setLoading(true);
        setSuccessMessage('');

        const url = NEWURL;

        const requestOptions = {
            method: 'POST',
            headers: { 'Accept': 'application/json' },
            body: JSON.stringify({
                "serviceId": 4003, //Slip Send //4003
                "token": sessionStorage.getItem('token'),
                "vendorId": NEWVENDORID,
                "slipRef": sessionStorage.getItem('slipRef'),
                "erpGenExpl1": sessionStorage.getItem('Expl1') ?? "",
                "erpGenExpl2": sessionStorage.getItem('Expl2') ?? ""
            })
        };

        const fetchData = async () => {
            try {
                const response = await fetch(url, requestOptions);
                const json = await response.json();

                console.log(json);
                if (json.resCode == 200) {
                    sessionStorage.setItem('slipRef', json.dataSet[0]?.slipRef);
                    sessionStorage.removeItem('Expl1');
                    sessionStorage.removeItem('Expl2');

                    props.onSlipDeleteAllLines();
                    props.showToast('Sent');
                    setSuccessMessage('Başarılı');
                    props.showSuccess(true);
                }else{
                    console.log("not");
                    props.errorText(json.resMessage)
                    props.errorModal(true)
                }
            } catch (error) {
                props.errorText(error)
                props.errorModal(true)
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    };

    return (
        <div>
            {loading ? (
                <div className=" rounded-xl overflow-auto p-2 justify-center items-center flex overflow-x-hidden
overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black opacity-50">
                <button type="button" className="inline-flex items-center font-semibold leading-6 p-4
                text-xl shadow rounded-md text-white bg-company-primary-color transition ease-in-out duration-150 cursor-not-allowed">

                  <svg className="animate-spin h-16 w-16 p-2 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth={"4"}></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>

                </button>
            </div>

            ) : (
                <button
                    onClick={() => sendOrder()}
                    className="flex items-center px-2 justify-center rounded-md border border-transparent bg-company-primary-color text-base font-medium text-white shadow-sm hover:opacity-75"
                >
                    <div className="p-1">
                        <Text tid="SendOrder" />
                    </div>
                </button>
            )}


        </div>
    );
}
